import axios from "axios"
import { apiUrl } from "../constants/defaultValue"
export const callApi=async(method,url,data)=>{
    try{
        const response=await axios({
            method,
            url:`${apiUrl}/${url}`,
            data
        })
        return response
    }catch(err){
        return err
       
    }
}