import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import EarlyBird from './pages/EarlyBird';




function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
         
          <Route path="/" element={<EarlyBird/>} />
          
          {/* <Route path="/*" element={<EarlyBird/>} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
