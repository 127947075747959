import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { apiUrl } from '../../constants/defaultValue';

import { printDocument } from '../../utils/PrintDocument';
import bmcLogo from '../Assets/BMC-logo.jpeg';
import hdfcLogo from '../Assets/hdfc.png';
import './PdfDownload.css';
import { uploadFile } from 'react-s3';
import { config } from '../Form/EarlyBirdForm';

const PDFTemplate = ({ details, getOrders }) => {
    const [challanMax, setChallanMax] = useState(true)
    const [orders, setOrders] = useState([])
    let {
        TxnId,
        bankTransactionId,
        time,
        home,
        name,
        selectedScheme,
        selectedSubScheme,
        orderId,
        accountNo,
        street,
        pincode,
        fileNo,
        mob,
        paymentMode,
        amount,
        paymentStatus
    } = details;

    const getCurrentDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

    useEffect(() => {
        if (Object.keys(details).length === 0) {
            name = 'Srikanta';
            selectedScheme = 'BUILDING PLAN FEE';
            selectedSubScheme = 'SCRUTINY FEE';
            fileNo = 12;
            mob = 9876543712;
            paymentMode = 'Offline';
        }
        amount = 126;
    }, [])
    return (
        <div className='container d-flex flex-column' >
            <div className="my-5 text-center">
                <button onClick={() => printDocument('divToPrint', orderId)} className="btn btn-primary w-50 rounded">Download Challan</button>
            </div>
            <p
                onClick={() => getOrders()}
                className="text-center w-auto my-2 mx-1 py-1"
                style={{ color: '#FF0000', cursor: 'pointer', textDecoration: "underline" }}
            >
                Update / View Payments
            </p>
            {/* <div id="" className="m-auto" >
                <div className='d-flex justify-content-between align-items-center p-3 challan'>
                    <div className='border px-4 pt-5 position-relative'
                        style={!challanMax ? { height: '200px', overflowY: 'scroll' } : {}}
                    >
                        <div className='d-flex justify-content-end'>
                            <button className=' mx-2 text-end' onClick={() => { setChallanMax(!challanMax) }} style={{ background: '#FF0000', color: '#fff', border: '1px solid #FF0000' }}>
                                {!challanMax ?
                                    <span> <i className='fa fa-long-arrow-down'></i></span>
                                    :
                                    <span> <i className='fa fa-long-arrow-up'></i></span>
                                }
                            </button>
                        </div>
                        <div>
                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                        </div>

                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p>Account Number : </p>
                            <p>Date :</p>
                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p className='font-weight-bold'>{accountNo}</p>
                            <p>{getCurrentDate()}</p>
                        </div>
                        <div className='text-center my-1'>
                            <p className='font-weight-bold'>Order - {orderId}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                        </div>
                        <div className='my-3'>
                            <p className='my-2'>Application Details</p>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td className='text-start'>File No</td>
                                        <td className='text-end'>{fileNo}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Scheme Name</td>
                                        <td className='text-end'>{selectedScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Sub Scheme Name</td>
                                        <td className='text-end'>{selectedSubScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Name</td>
                                        <td className='text-end'>{name}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Contact Number</td>
                                        <td className='text-end'>{mob}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-between align-items-center my-4'>
                                <p>Applicant Other Details</p>
                            </div>
                        </div>
                        <div>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td>House/Flat No</td>
                                        <td>{home}</td>
                                    </tr>
                                    <tr>
                                        <td>Street</td>
                                        <td>{street}</td>
                                    </tr>
                                    <tr>
                                        <td>Pincode</td>
                                        <td>{pincode}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='my-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {amount}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                }
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold'>{paymentMode}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start '>Check/DD : </p>
                                }
                            </div>
                        </div>
                        <div className='my-3'>
                            {
                                paymentMode === 'Online' ?
                                    <div>
                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                        <p className=''>Payment Details</p>
                                        <table className="table border my-4">
                                            <tbody>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <td>{orderId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Txn. ID</th>
                                                    <td>{TxnId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Bank Txn. ID</th>
                                                    <td>{bankTransactionId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date</th>
                                                    <td>{getCurrentDate()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Time</th>
                                                    <td>{time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Payment Status</th>
                                                    <td>{paymentStatus}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :

                                    <div className='my-3'>
                                        <table className="table table-bordered my-3">
                                            <thead>
                                                <tr>
                                                    <td className='text-center'>Chq/DD No.</td>
                                                    <td className='text-center'>Chq/DD Date</td>
                                                    <td className='text-center'>Bank/Branch</td>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr style={{ height: '5rem' }}>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='sticky-bottom'>
                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div> */}

            <div id="divToPrint" style={{ width: '35cm', height: '40cm', display: "none" }} >
                <div className='d-flex justify-content-between align-items-center p-3 challan'>
                    <div className='mx-2 border px-4 pt-5 position-relative'
                        style={!challanMax ? { height: '200px', overflowY: 'scroll' } : {}}
                    >
                        <div>
                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                        </div>

                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p>Account Number : </p>
                            <p>Date :</p>
                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p className='font-weight-bold'>{accountNo}</p>
                            <p>{getCurrentDate()}</p>
                        </div>
                        <div className='text-center my-1'>
                            <p className='font-weight-bold'>Order - {orderId}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                        </div>
                        <div className='my-3'>
                            <p className='my-2'>Application Details</p>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td className='text-start'>File No</td>
                                        <td className='text-end'>{fileNo}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Scheme Name</td>
                                        <td className='text-end'>{selectedScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Sub Scheme Name</td>
                                        <td className='text-end'>{selectedSubScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Name</td>
                                        <td className='text-end'>{name}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Contact Number</td>
                                        <td className='text-end'>{mob}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-between align-items-center my-4'>
                                <p>Applicant Other Details</p>
                            </div>
                        </div>
                        <div>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td>House/Flat No</td>
                                        <td>{home}</td>
                                    </tr>
                                    <tr>
                                        <td>Street</td>
                                        <td>{street}</td>
                                    </tr>
                                    <tr>
                                        <td>Pincode</td>
                                        <td>{pincode}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='my-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {amount}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                }
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold'>{paymentMode}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start '>Check/DD : </p>
                                }
                            </div>
                        </div>
                        <div className='my-3'>
                            {
                                paymentMode === 'Online' ?
                                    <div>
                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                        <p className=''>Payment Details</p>
                                        <table className="table border my-4">
                                            <tbody>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <td>{orderId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Txn. ID</th>
                                                    <td>{TxnId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Bank Txn. ID</th>
                                                    <td>{bankTransactionId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date</th>
                                                    <td>{getCurrentDate()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Time</th>
                                                    <td>{time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Payment Status</th>
                                                    <td>{paymentStatus}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :

                                    <div className='my-3'>
                                        <table className="table table-bordered my-3">
                                            <thead>
                                                <tr>
                                                    <td className='text-center'>Chq/DD No.</td>
                                                    <td className='text-center'>Chq/DD Date</td>
                                                    <td className='text-center'>Bank/Branch</td>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr style={{ height: '5rem' }}>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='sticky-bottom'>
                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className='mx-2 border px-4 pt-5 position-relative'
                        style={!challanMax ? { height: '200px', overflowY: 'scroll' } : {}}
                    >
                        <div>
                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                        </div>

                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p>Account Number : </p>
                            <p>Date :</p>
                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p className='font-weight-bold'>{accountNo}</p>
                            <p>{getCurrentDate()}</p>
                        </div>
                        <div className='text-center my-1'>
                            <p className='font-weight-bold'>Order - {orderId}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                        </div>
                        <div className='my-3'>
                            <p className='my-2'>Application Details</p>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td className='text-start'>File No</td>
                                        <td className='text-end'>{fileNo}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Scheme Name</td>
                                        <td className='text-end'>{selectedScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Sub Scheme Name</td>
                                        <td className='text-end'>{selectedSubScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Name</td>
                                        <td className='text-end'>{name}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Contact Number</td>
                                        <td className='text-end'>{mob}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-between align-items-center my-4'>
                                <p>Applicant Other Details</p>
                            </div>
                        </div>
                        <div>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td>House/Flat No</td>
                                        <td>{home}</td>
                                    </tr>
                                    <tr>
                                        <td>Street</td>
                                        <td>{street}</td>
                                    </tr>
                                    <tr>
                                        <td>Pincode</td>
                                        <td>{pincode}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='my-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {amount}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                }
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold'>{paymentMode}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start '>Check/DD : </p>
                                }
                            </div>
                        </div>
                        <div className='my-3'>
                            {
                                paymentMode === 'Online' ?
                                    <div>
                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                        <p className=''>Payment Details</p>
                                        <table className="table border my-4">
                                            <tbody>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <td>{orderId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Txn. ID</th>
                                                    <td>{TxnId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Bank Txn. ID</th>
                                                    <td>{bankTransactionId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date</th>
                                                    <td>{getCurrentDate()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Time</th>
                                                    <td>{time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Payment Status</th>
                                                    <td>{paymentStatus}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :

                                    <div className='my-3'>
                                        <table className="table table-bordered my-3">
                                            <thead>
                                                <tr>
                                                    <td className='text-center'>Chq/DD No.</td>
                                                    <td className='text-center'>Chq/DD Date</td>
                                                    <td className='text-center'>Bank/Branch</td>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr style={{ height: '5rem' }}>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='sticky-bottom'>
                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className='mx-2 border px-4 pt-5 position-relative'
                        style={!challanMax ? { height: '200px', overflowY: 'scroll' } : {}}
                    >
                        <div>
                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                        </div>

                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p>Account Number : </p>
                            <p>Date :</p>
                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p className='font-weight-bold'>{accountNo}</p>
                            <p>{getCurrentDate()}</p>
                        </div>
                        <div className='text-center my-1'>
                            <p className='font-weight-bold'>Order - {orderId}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                        </div>
                        <div className='my-3'>
                            <p className='my-2'>Application Details</p>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td className='text-start'>File No</td>
                                        <td className='text-end'>{fileNo}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Scheme Name</td>
                                        <td className='text-end'>{selectedScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Sub Scheme Name</td>
                                        <td className='text-end'>{selectedSubScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Name</td>
                                        <td className='text-end'>{name}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Contact Number</td>
                                        <td className='text-end'>{mob}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-between align-items-center my-4'>
                                <p>Applicant Other Details</p>
                            </div>
                        </div>
                        <div>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td>House/Flat No</td>
                                        <td>{home}</td>
                                    </tr>
                                    <tr>
                                        <td>Street</td>
                                        <td>{street}</td>
                                    </tr>
                                    <tr>
                                        <td>Pincode</td>
                                        <td>{pincode}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='my-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {amount}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                }
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold'>{paymentMode}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start '>Check/DD : </p>
                                }
                            </div>
                        </div>
                        <div className='my-3'>
                            {
                                paymentMode === 'Online' ?
                                    <div>
                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                        <p className=''>Payment Details</p>
                                        <table className="table border my-4">
                                            <tbody>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <td>{orderId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Txn. ID</th>
                                                    <td>{TxnId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Bank Txn. ID</th>
                                                    <td>{bankTransactionId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date</th>
                                                    <td>{getCurrentDate()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Time</th>
                                                    <td>{time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Payment Status</th>
                                                    <td>{paymentStatus}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :

                                    <div className='my-3'>
                                        <table className="table table-bordered my-3">
                                            <thead>
                                                <tr>
                                                    <td className='text-center'>Chq/DD No.</td>
                                                    <td className='text-center'>Chq/DD Date</td>
                                                    <td className='text-center'>Bank/Branch</td>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr style={{ height: '5rem' }}>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='sticky-bottom'>
                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className='mx-2 border px-4 pt-5 position-relative'
                        style={!challanMax ? { height: '200px', overflowY: 'scroll' } : {}}
                    >
                        <div>
                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                        </div>

                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p>Account Number : </p>
                            <p>Date :</p>
                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center'>
                            <p className='font-weight-bold'>{accountNo}</p>
                            <p>{getCurrentDate()}</p>
                        </div>
                        <div className='text-center my-1'>
                            <p className='font-weight-bold'>Order - {orderId}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                        </div>
                        <div className='my-3'>
                            <p className='my-2'>Application Details</p>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td className='text-start'>File No</td>
                                        <td className='text-end'>{fileNo}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Scheme Name</td>
                                        <td className='text-end'>{selectedScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Sub Scheme Name</td>
                                        <td className='text-end'>{selectedSubScheme}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Name</td>
                                        <td className='text-end'>{name}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start'>Contact Number</td>
                                        <td className='text-end'>{mob}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-between align-items-center my-4'>
                                <p>Applicant Other Details</p>
                            </div>
                        </div>
                        <div>
                            <table className="table border my-3">
                                <tbody>
                                    <tr>
                                        <td>House/Flat No</td>
                                        <td>{home}</td>
                                    </tr>
                                    <tr>
                                        <td>Street</td>
                                        <td>{street}</td>
                                    </tr>
                                    <tr>
                                        <td>Pincode</td>
                                        <td>{pincode}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='my-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {amount}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                }
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-start font-weight-bold'>{paymentMode}</p>
                                {
                                    paymentMode === 'Offline' && <p className='text-start '>Check/DD : </p>
                                }
                            </div>
                        </div>
                        <div className='my-3'>
                            {
                                paymentMode === 'Online' ?
                                    <div>
                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                        <p className=''>Payment Details</p>
                                        <table className="table border my-4">
                                            <tbody>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <td>{orderId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Txn. ID</th>
                                                    <td>{TxnId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Bank Txn. ID</th>
                                                    <td>{bankTransactionId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date</th>
                                                    <td>{getCurrentDate()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Time</th>
                                                    <td>{time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Payment Status</th>
                                                    <td>{paymentStatus}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :

                                    <div className='my-3'>
                                        <table className="table table-bordered my-3">
                                            <thead>
                                                <tr>
                                                    <td className='text-center'>Chq/DD No.</td>
                                                    <td className='text-center'>Chq/DD Date</td>
                                                    <td className='text-center'>Bank/Branch</td>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr style={{ height: '5rem' }}>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='sticky-bottom'>
                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PDFTemplate