import React from 'react'
import EarlyBirdForm from '../components/Form/EarlyBirdForm'


export default function EarlyBird() {
  return (
    <div>
       <header className="hero">
            <div className="hero-wrapper">
               
                <EarlyBirdForm/>
                
            </div>
        </header> 

    </div>
  )
}
