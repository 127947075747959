
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const printDocument = async (id,pdfName) => {
    console.log("print function called")
    let input = document.getElementById(id);
    html2canvas(input,{
        onclone:function(clonedDoc){
            clonedDoc.getElementById(id).style.display = 'block';

        }
    })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'pt', 'a4',true);
            const imgProperties = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight =
                (imgProperties.height * pdfWidth * 0.77) / imgProperties.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(pdfName+".pdf");
        //     document.getElementById(id).style.display = 'none';

        //     input=null
        });
}