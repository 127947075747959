import React, { useState, useEffect, useRef } from 'react';
// import { URLSearchParams } from 'react-router-dom';
import axios from 'axios';
import OtpInput from "react-otp-input";
import OtpTimer from "otp-timer";
import { apiUrl } from '../../constants/defaultValue';
import { useForm } from "react-hook-form";
import ReactTypingEffect from 'react-typing-effect';
import CreatableSelect from 'react-select/creatable';
import { useSearchParams } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { uploadFile } from 'react-s3'
import { capitalCase } from 'change-case';

import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import { styles } from '../../utils/Loader'
import { callApi } from '../../utils/Api';
import PDFTemplate from '../PdfDownload/pdfTemplate';
import { printDocument } from '../../utils/PrintDocument';
import './EarlyBirdForm.css';
import bmcLogo from '../Assets/BMC-logo.jpeg';
import hdfcLogo from '../Assets/hdfc.png';

window.Buffer = window.Buffer || require("buffer").Buffer;
const colourOptions = [
    {
        value: {
            name: 'BUILDING PLAN FEE',
            accountNo: '50200020531742',
            schemeName: [
                'SCRUTINY FEE',
                'BUILDING SANCTION FEE',
                'SECURITY DEPOSIT',
                'LAND DEVELOPMENT FEE',
                'FAR',
                'SHELTER',
                'EIDP',
                'FF FEE',
                'COMPOUNDING FEE FOR SUBPLOT',
                'RETENTION FEE',
                'OCCUPANCY',
                'OTHERS',
            ]
        },
        label: 'BUILDING PLAN FEE',
        color: '#00B8D9',
        isFixed: true
    },
    {
        value: {
            name: 'BMC NOC USER FEE',
            accountNo: '50100328599962',
            schemeName: [
                'USER FEE',
                'NOC',
                'OTHERS',
            ]
        },
        label: 'BMC NOC USER FEE',
        color: '#00B8D9',
        isFixed: true
    },
    {
        value: {
            name: 'CWWC',
            accountNo: '50100583839882',
            schemeName: [
                'CWWC FEE',
                'OTHERS',
            ]
        },
        label: 'CWWC',
        color: '#00B8D9',
        isFixed: true
    },
    {
        value: {
            name: 'CIDF',
            accountNo: '50100583838892',
            schemeName: [
                'SHELTER FEE(CIDF)',
                'PURCHASABLE FAR (CIDF)',
                'COMPOUND FEE(CIDF)',
                'Others'
            ]
        },
        label: 'CIDF',
        color: '#00B8D9',
        isFixed: true
    },
    {
        value: {
            name: 'DEVELOPMENT FEE',
            accountNo: '50100328590532',
            schemeName: [
                'PERIPHERAL DEVELOPMENT FEE',
                'Others'
            ]
        },
        label: 'DEVELOPMENT FEE',
        color: '#00B8D9',
        isFixed: true
    },
    // { value: '2', label: 'option 5', color: '#00B8D9', isFixed: true },
    // { value: '2', label: 'option 6', color: '#00B8D9', isFixed: true },

];

const S3_BUCKET = 'etaiiler-image-upload';
const REGION = 'ap-south-1';
const ACCESS_KEY = 'AKIATPAILJSQ3KSDD3P4';
const SECRET_ACCESS_KEY = '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';

export const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

const getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return today;
}

export default function EarlyBirdForm() {
    const [orders, setOrders] = useState([]);
    const [downloadItems, setDownloadItems] = useState(null);
    const [searchParam, setSearchParam] = useSearchParams();
    const [prgId, setPrgId] = useState(undefined);
    const [refId, setRefId] = useState(undefined);
    const [programID, setProgramID] = useState(undefined);
    const [referID, setReferID] = useState(undefined);
    const prgIdHand = searchParam.get("prgid");
    const refIdHand = searchParam.get("refid");
    const paymentResPageOrderId = searchParam.get("paymentResPageOrderId");
    const [isSearchable, setIsSearchable] = useState(true);
    const [error, setError] = useState({ otp: '', scheme: '' });
    const [selectSchemeNameOptions, setSelectSchemeNameOptions] = useState();
    const [isPaymentResPage, setIsPaymentResPage] = useState(false);
    // const [ isPaymentResPage, setIsPaymentResPage ] = useState(false);
    // const [searchParams, setSearchParams ] = useSearchParams();
    // const params__ = URLSearchParams();



    useEffect(() => {
        if (paymentResPageOrderId && paymentResPageOrderId.length > 4) {
            setIsPaymentResPage(true)
            setStatusCode(8);

            intervalRef.current = (setInterval(() => {
                getOrderPaymentResponse()
            }, 3000))
            return () => clearInterval(intervalRef.current)

        }
    }, [paymentResPageOrderId])




    // ****************************** all states ******************************

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(undefined)
    const [mobileMessage, setMobileMessage] = useState(undefined)
    const [statuscode, setStatusCode] = useState(0)
    const [otp, setOTP] = useState(undefined);
    const [referalcode, setReferalCode] = useState(undefined);
    const [otpMessage, setOtpMessage] = useState(undefined);
    const [fileNo, setFileNo] = useState('')
    const [bussinessName, setBussinessName] = useState('')
    const [applicantName, setApplicantName] = useState(undefined)
    const [intrestedIn, setIntrestedIn] = useState(false)
    const [intrestedInMessage, setIntrestedInMessage] = useState(undefined)
    const [referallinkdata, setReferallinkdata] = useState(undefined)
    //****************************** react hooks form ******************************
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // ******************************** select tag options ******************************
    const [url, setUrl] = useState(undefined);
    const [rcode, setRcode] = useState(undefined);
    const [options, setOptions] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [reviewFormLoading, setReviewFormLoading] = useState(false);
    const [shareMsg, setShareMsg] = useState(undefined);
    const [selectedScheme, setSelectedScheme] = useState();
    const [selectedSchemeName, setSelectedSchemeName] = useState();
    const [home, setHome] = useState('')
    const [street, setStreet] = useState('')
    const [pincode, setPincode] = useState('')
    const [amount, setAmount] = useState('')
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
    const [details, setDetails] = useState({});
    const [paymentDetails, setPaymentDetails] = useState(undefined);
    const [paymentStatus, setPaymentStatus] = useState(undefined);
    const [intervalTim, setIntervalTim] = useState(undefined);
    const [reqChlnUploadOffline, setReqChlnUploadOffline] = useState([]);

    const intervalRef = useRef(null)





    // ********************************************** function of otp generate ******************************

    const onSubmit = (data) => {
        setReviewFormLoading(true);


        let data1 = { phoneNumber: phoneNumber, api_key: 'registeruser' }

        axios.post(`${apiUrl}/epay/getEpayRoutes/epayLogin`, data1).then(res => {

            setMobileMessage('Otp Sent to your mobile');
            setReviewFormLoading(false);
            if (res.data.code === 200) {
                setStatusCode(1)
            }
        }).catch(err => {
            setStatusCode(0)
            setReviewFormLoading(false);

        })

        // setTimeout(() => {
        //     setMobileMessage({});
        //     setReviewFormLoading(false);
        // }, 2000);

    };

    // ********************************************** function of add data of details from  ******************************
    const onSubmitData = async (data) => {
        if (!selectedScheme || Object.keys(selectedScheme).length === 0) {
            setError({ ...error, scheme: 'Select a Scheme' });
            return false
        }
        if (!selectedSchemeName || Object.keys(selectedSchemeName).length === 0) {
            setError({ ...error, schemeName: 'Select a Scheme Name' });
            return false
        }
        setDetails({
            ...details,
            name: data.applicantName,
            amount: data.amount,
            accountNo: selectedScheme.value.accountNo,
            home: data.home,
            mob: data.phoneNumber,
            selectedScheme: selectedScheme.value.name,
            selectedSubScheme: selectedSchemeName.value,
            street: data.street,
            pincode: data.pincode,
            fileNo: data.fileNo,
            //paymentMode
            //orderId
            //paymentStatus
        })
        // paymentMode: selectedPaymentOption,
        setReviewFormLoading(true);


        setTimeout(() => {
            setStatusCode(5);
            setReviewFormLoading(false)
        }, 1000);
    }
    const onSubmitData1 = async (data) => {

        setReviewFormLoading(true);
        const token = await localStorage.getItem('token');
        const userId = await localStorage.getItem('userId');

        const res = await axios({
            method: 'post',
            url: `${apiUrl}/epay/order/addOrder`,
            headers: {
                'Authorization': `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                api_key: 'registeruser',
                fileNo: fileNo,
                paymentMode: selectedPaymentOption?.value,
                applicantName: applicantName,
                scheme: selectedScheme.value.name,
                subScheme: selectedSchemeName.value,
                user: userId,
                paymentAccountNumber: selectedScheme?.value?.accountNo,
                amount: amount,
                others: {
                    street: street,
                    pincode: pincode
                }
            }
        });

        if (res.data.code === 200 && selectedPaymentOption?.value) {
            if (selectedPaymentOption?.value === 'Online') {
                console.log({ paymentDetails: res.data })
                setPaymentDetails(res?.data?.paymentData);

            }
            setDetails({
                ...details,
                paymentMode: selectedPaymentOption?.value,
                orderId: res.data.data.orderId
            })
            if (selectedPaymentOption?.value === 'Online') {
                setStatusCode(8);
            }
            else {
                setStatusCode(3)
            }
            await localStorage.setItem('order', res.data.data._id)
        }
        setReviewFormLoading(false);

        // var data1 = {}
        // if (referalcode) {
        //     data1 = {
        //         email: email,
        //         referalCode: referalcode,
        //         bussinessName: bussinessName,
        //         bussinessType: selectedOptions,
        //         bussinessLocation: bussinessLocation,
        //         phoneNumber: phoneNumber,
        //         intrestedIn: false,
        //         api_key: 'registeruser'
        //     }
        // } else {
        //     data1 = {
        //         email: email,
        //         bussinessName: bussinessName,
        //         bussinessType: selectedOptions,
        //         bussinessLocation: bussinessLocation,
        //         phoneNumber: phoneNumber,
        //         intrestedIn: false,
        //         api_key: 'registeruser'
        //     }
        // }
        // axios.post(`${apiUrl}/earlyReg/earlyCurd/addEarlyReg`, data1
        // ).then(res => {

        //     setReviewFormLoading(false);
        //     setMessage(res.data.message);
        //     if (res.data.data[0]?.program) {
        //         localStorage.setItem("prgid", res.data.data[0]?.program.toString());
        //     }
        //     if (res.data.data[0]?._id) {
        //         localStorage.setItem("refid", res.data.data[0]?._id.toString());
        //     }
        //     if (res.data.code === 200) {
        //         createReferalLink();
        //         setStatusCode(3);
        //     }
        // }).catch(err => {
        //     setReviewFormLoading(false);
        // })
        // setTimeout(() => {
        //     setStatusCode(3);
        //     setReviewFormLoading(false);

        // }, 1000);

    }
    // ********************************************** function of resend otp ******************************
    const [resend, setResend] = useState('');
    const resendOTP = () => {
        axios.post(`${apiUrl}/earlyReg/earlyCurd/generateOtp`, { phoneNumber: phoneNumber, api_key: 'registeruser' }).then(res => {

            if (res.data.code === 200) {
                setResend(res.data.message);
            }
        }).catch(err => {
            setStatusCode(0)

        })
    }

    // ********************************************** function of validate otp ******************************
    const handleOtp = (e) => {
        e.preventDefault();
        if (!otp || otp.length !== 6) {
            setError({ ...error, otp: 'Enter a valid Otp.' });
            return false;
        }
        else {
            setReviewFormLoading(true);
        }
        axios.post(`${apiUrl}/epay/getEpayRoutes/epayValidateOtp`, { otp: otp, phoneNumber: phoneNumber, api_key: 'registeruser' }).then(async res => {
            setOtpMessage(res.data.message);
            // if (res) {
            //     setBussinessName(res.data.data.userData.bussinessName);
            //     setBussinessLocation(res.data.data.userData.bussinessLocation);
            //     setEmail(res.data.data.userData.email);
            //     setSelectedOptions(res.data.data.userData.bussinessType);
            // }
            setReviewFormLoading(false);
            if (res.data.code === 200) {
                await localStorage.setItem('token', res.data.data.token);
                await localStorage.setItem('userId', res.data.data.userData._id);
                await getOfflineOrders(res.data.data.token);
                setStatusCode(7);
            }
        }).catch(err => {
            setReviewFormLoading(false);
        })

        // setTimeout(() => {
        //     setReviewFormLoading(false);
        //     setStatusCode(7);
        // }, 1000);

    }
    // ********************************************** function of handel intrest in ******************************
    const handleIntrestedIn = (e) => {
        e.preventDefault();
        axios.post(`${apiUrl}/earlyReg/earlyCurd/intrestedIn`,
            {
                phoneNumber: phoneNumber,
                intrestedIn: intrestedIn,
            }).then(res => {
                setIntrestedInMessage(res.data.message);
                if (res.data.code === 200) {
                    setStatusCode(4);
                }
            }).catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        axios.post(`${apiUrl}/service/service/getAllserviceKeys`).then(res => {
            let key = Object.keys(res.data.data);

            let dropdown = [];
            key.map(function (val, key) {
                dropdown.push({ value: val, label: val, key: key })
            }
            )
            setServiceOptions(dropdown)
        }).catch(err => {
            console.log(err)
        }
        )
        axios.post(`${apiUrl}/category/curd/getAllKeys`).then(res => {
            let key = Object.keys(res.data.data);

            let dropdown = [];
            key.map(function (val, key) {
                dropdown.push({ value: val, label: val, key: key })
            }
            )
            setOptions(dropdown)
            options.concat(serviceOptions)
        }).catch(err => {
            console.log(err)
        }
        )
    }, [])


    // ********************************************** useeffects of otp and status code ******************************

    function refreshPage() {
        setTimeout(() => {
            window.location.reload(false);
        }, 500);
    }


    const handleScheme = (e) => {
        setSelectedScheme(e);
        setSelectSchemeNameOptions(e.value.schemeName.map((item) => {
            return {
                label: item,
                value: item,
                color: '#00B8D9',
                isFixed: true
            }
        }));
    }

    const getOrders = async () => {
        const token = await localStorage.getItem('token');
        console.log({ token })
        const res = await axios({
            method: 'post',
            url: `${apiUrl}/epay/order/viewAllEpayOrdersByUserId`,
            headers: {
                'Authorization': `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                api_key: "registeruser"
            }
        });
        if (res.data.code === 200) {
            setOrders(res.data.data);
            // .map((item) => {
            //     return {
            //         name: item.applicantName,
            //         fileNo: item.fileNo,
            //         scheme: item.scheme,
            //         subScheme: item.subScheme,
            //         amount: item.others.amount,
            //         paymentMode: item.paymentMode,
            //         _id : item._id
            //     }
            // })
            setStatusCode(6);
        }
    }



    const getOrderPaymentResponse = async () => {

        setReviewFormLoading(true);
        const token = await localStorage.getItem('token');
        const order_id = paymentResPageOrderId;

        const res = await axios({
            method: 'post',
            url: `${apiUrl}/epay/order/getOrder`,
            headers: {
                'Authorization': `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                api_key: 'registeruser',
                _id: order_id
            }
        });

        if (res.data.code === 200) {

            setDetails({
                ...details,
                name: res?.data?.order?.applicantName,
                amount: res?.data?.order?.amount,
                accountNo: res?.data?.order?.paymentAccountNumber,
                home: res?.data?.order?.others?.street,
                mob: res?.data?.order?.phoneNumber,
                selectedScheme: res?.data?.order?.scheme,
                selectedSubScheme: res?.data?.order?.subScheme,
                street: res?.data?.order?.others?.street,
                pincode: res?.data?.order?.others?.pincode,
                fileNo: res?.data?.order?.fileNo,
                paymentMode: "Online",
                orderId: res?.data?.order?.orderId,
                paymentStatus: res?.data?.order?.paymentStatus,
                time: new Date().toLocaleTimeString(),
                TxnId: res?.data?.order?.bankTransaction?.epayBankTxnId,
                bankTransactionId: res?.data?.order?.bankTransaction?.bankTransaction?.tracking_id,
            })
            setPaymentStatus(res?.data?.order?.paymentStatus);
        }

    }

    useEffect(() => {
        console.log("called payment status")
        if (paymentStatus && paymentStatus == 'SUCCESS') {
            console.log("called payment status success")

            setDetails({ ...details, paymentStatus: paymentStatus, time: new Date().toLocaleTimeString() });
            setStatusCode(3);
            setReviewFormLoading(false);
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
        if (paymentStatus && paymentStatus == 'FAILED') {
            console.log("called payment status failed")

            setDetails({ ...details, paymentStatus: 'Failed' });
            setStatusCode(9);
            setReviewFormLoading(false);

            clearInterval(intervalRef.current)
            intervalRef.current = null

        }
    }, [paymentStatus])

    const getOfflineOrders = async (token) => {
        const requiredToken = token ? token : await localStorage.getItem('token');
        const offlineOrders = await axios({
            method: 'post',
            url: `${apiUrl}/epay/order/getOfflineOrders`,
            headers: {
                'Authorization': `Bearer ${requiredToken}`,
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                api_key: "registeruser",
            }
        })
        console.log({ offlineOrders });
        if (offlineOrders.data.code === 200) {
            setReqChlnUploadOffline(offlineOrders.data.data);
            // await getOrders()
        }
    }

    const handleChange = async (event, item) => {
        event.preventDefault();
        const token = await localStorage.getItem('token');
        try {
            const data = await uploadFile(event.target.files[0], config)
            console.log({ data, item });

            const res = await axios({
                method: 'post',
                url: 'https://backend.epay.bmc.teceads.co.in/api/v1/epay/order/uploadChallan',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                },
                data: {
                    _id: item._id,
                    api_key: "registeruser",
                    offlineChallanUrl: data.location
                }
            })
            console.log('res ====', res)
            await getOrders();
        } catch (error) {
            console.log("error =====", error)
        }
        // ReactS3Client.uploadFile(file, newFileName).then(data => {
        //     console.log('data =====',data);
        //     if (data.status === 204) {
        //         console.log('success ====')
        //     } else {
        //         console.log('Fail')
        //     }
        // });
    }

    const downloadPDF = async (item) => {
        console.log({ item })
        setDownloadItems({ ...item })
    }
    useEffect(() => {
        setTimeout(() => {
            document.getElementById("nonseamless").submit();
        }, 600);
    }, [paymentDetails])

    return (
        <div>
            <div className="page-title">
                <div className="container">
                    <a onClick={refreshPage}>
                        <div className="text-center">

                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/5/57/Bhubaneswar-municipal-corporation-logo.jpg/170px-Bhubaneswar-municipal-corporation-logo.jpg" className='logo' alt="Mayifind" />

                        </div>
                    </a>




                    <h1 className=" center">
                        Pay  <a style={{ color: "#ff0000" }} href="/">
                            <ReactTypingEffect
                                text={["AT EASE", "SECURELY", "WITH FEW CLICKS"]}
                                speed={100} eraseSpeed={50} eraseDelay={1000} typingDelay={1000}
                            />
                        </a> <br /> to <span className='may-3'>BMC </span>
                    </h1>
                </div>
            </div>

            <div>
                {(() => {

                    switch (statuscode) {
                        case 1:
                            return (
                                <LoadingOverlay
                                    active={reviewFormLoading}
                                    spinner
                                    styles={styles()}
                                >
                                    <form className="hero-form form">
                                        <div className="container">
                                            <div className="main-search-form">
                                                <div className="form-row">
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4 col-12 ">

                                                        <div className="form-group">
                                                            <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Your Mobile Number</label>
                                                            <input name="PhoneNumber" value={phoneNumber} type="text" className="form-control" disabled />
                                                        </div>

                                                        <br /><br />
                                                        <div className="form-group">
                                                            <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Enter Verification Code</label>
                                                            <OtpInput
                                                                onChange={setOTP}
                                                                numInputs={6}
                                                                isInputNum={true}
                                                                separator={<span >-</span>
                                                                } inputStyle={{
                                                                    width: "4.2rem",
                                                                    height: "5rem",

                                                                    fontSize: "1.2rem",
                                                                    borderRadius: 4,
                                                                    color: "black",
                                                                    border: "1px solid rgba(0,0,0,0.3)"
                                                                }}

                                                                value={otp}
                                                                name='otp'
                                                            // {...register('otp',{required: 'Enter a valid otp', length: 6})}
                                                            />
                                                            <span className='text-danger'>

                                                                {otpMessage}
                                                            </span>


                                                        </div>
                                                        <p style={{ color: '#ff0000' }}>{error?.otp}</p>
                                                        <div className="text-center">
                                                            <button onClick={(e) => { handleOtp(e) }} className="btn btn-primary width-100">Verify <span><i className='fa fa-long-arrow-right'></i></span></button>
                                                        </div>

                                                        <div className="text-center mt-2">
                                                            <OtpTimer
                                                                minutes={0}
                                                                seconds={60}
                                                                text="Resend in:"
                                                                ButtonText="Resend verification code"
                                                                resend={resendOTP}
                                                                buttonColor="#ff0000"
                                                                textColor="#ff0000"
                                                                background="transparent"
                                                                fontWeight="bold"
                                                            />
                                                        </div>

                                                        <div className="text-center">
                                                            {resend ? "OTP sent to your mobile number" : null}
                                                        </div>

                                                    </div>

                                                    <div className="col-md-4"></div>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </form>
                                </LoadingOverlay>

                            )
                        case 2:
                            return (
                                <LoadingOverlay
                                    active={reviewFormLoading}
                                    spinner
                                    styles={styles()}
                                >
                                    <form className="hero-form form" onSubmit={handleSubmit(onSubmitData)}>
                                        <div className="container">
                                            <div className="main-search-form">
                                                <div className="row">
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Enter Details of Payments</label>
                                                            <input
                                                                name="fileNo"
                                                                {...register("fileNo", { required: "File No is required", minLength: 2, pattern: /^[0-9a-zA-Z -]*$/ })}
                                                                value={fileNo}
                                                                onChange={(e) => { setFileNo(e.target.value) }}
                                                                type="text"
                                                                // className={bussinessName?.length > 3 && bussinessName?.length != null && bussinessName.match(/^[0-9a-zA-Z ]+$/) ? "form-control is-valid" : "form-control is-invalid"}
                                                                id="what"
                                                                placeholder='File No'//"File No" 
                                                            />
                                                            <span style={{ color: '#ff0000' }}>{errors?.fileNo?.message}</span>
                                                            <span style={{ color: '#ff0000' }}>{errors.fileNo?.type === 'minLength' && "File No should be greater than 2 letter"}</span>
                                                            <span style={{ color: '#ff0000' }}>{errors.fileNo?.type === 'pattern' && "Please enter a Valid File No"}</span>
                                                        </div>


                                                        <div className="form-group">
                                                            <input name="applicantName"
                                                                {...register("applicantName", { required: "Applicant Name is required", minLength: 2, pattern: /^[a-zA-Z ]{3,}$/ })}
                                                                value={applicantName || ''}
                                                                onChange={(e) => { setApplicantName(e.target.value) }}
                                                                type="text"
                                                                // className={applicantName?.length > 3 && applicantName?.length != null && applicantName.match(/^[a-zA-Z0-9\s,. '-]{3,}$/) ? "form-control is-valid" : "form-control is-invalid"}
                                                                id="what"
                                                                placeholder="Applicant Name"
                                                            />
                                                            <span style={{ color: '#ff0000' }}>{errors.applicantName?.message}</span>
                                                            <span style={{ color: '#ff0000' }}>{errors.applicantName?.type === 'minLength' && "Applicant Name should be greater than 2 letter"}</span>
                                                            <span style={{ color: '#ff0000' }}>{errors.applicantName?.type === 'pattern' && "Please enter a Valid Applicant Name"}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            {/* <input name="bussinessType" value={bussinessType || ''} onChange={(e) => { setBussinessType(e.target.value) }} type="text" className={bussinessType.length>3 && bussinessType.length!=null ?"form-control is-valid":"form-control is-invalid"} id="what" placeholder="Business Type" required /> */}
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                placeholder="Select Scheme"
                                                                isSearchable={isSearchable}
                                                                name="scheme"
                                                                options={colourOptions}
                                                                value={selectedScheme}
                                                                onChange={(e) => handleScheme(e)}
                                                            // {...register('scheme',{ required : 'Select a Scheme'})}
                                                            />
                                                            <p style={{ color: '#ff0000' }}>{error?.scheme}</p>
                                                        </div>

                                                        {
                                                            (selectedScheme && Object.keys(selectedScheme).length > 0) &&
                                                            <div className="form-group">
                                                                {/* <input name="bussinessType" value={bussinessType || ''} onChange={(e) => { setBussinessType(e.target.value) }} type="text" className={bussinessType.length>3 && bussinessType.length!=null ?"form-control is-valid":"form-control is-invalid"} id="what" placeholder="Business Type" required /> */}
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select Scheme Name"
                                                                    isSearchable={isSearchable}
                                                                    name="schemeName"
                                                                    value={selectedSchemeName}
                                                                    onChange={(e) => setSelectedSchemeName(e)}
                                                                    options={selectSchemeNameOptions}
                                                                // {...register('schemeName',{ required : 'Select a Scheme Name'})}
                                                                />
                                                                <p style={{ color: '#ff0000' }}>{error?.schemeName}</p>
                                                            </div>
                                                        }


                                                        <div className="form-group">
                                                            <input
                                                                name="home"
                                                                {...register("home", { required: "House No is required" })} value={home || ''} //pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                                                                // className={email?.length != null && email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? "form-control is-valid" : "form-control is-invalid"}
                                                                onChange={(e) => { setHome(e.target.value) }}
                                                                type="text" id="what"
                                                                placeholder="House No / Flat No"
                                                            />
                                                            <span style={{ color: '#ff0000' }}>{errors.home?.message}</span>

                                                            {/* <span style={{ color : '#ff0000'}}>{errors.home?.type === 'pattern' && "Please enter a House No"}</span> */}
                                                        </div>

                                                        <div className="form-group">
                                                            <input name="text"
                                                                {...register("street", { required: "Street is required" })}
                                                                value={street || ''} //, pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i 
                                                                // className={email?.length != null && email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? "form-control is-valid" : "form-control is-invalid"}
                                                                onChange={(e) => { setStreet(e.target.value) }}
                                                                type="text"
                                                                id="what"
                                                                placeholder="Street"
                                                            />
                                                            <span style={{ color: '#ff0000' }}>{errors.street?.message}</span>

                                                            {/* <span>{errors.city?.type === 'pattern' && "Please enter a Email"}</span> */}
                                                        </div>
                                                        <div className="form-group">
                                                            <input name="text"
                                                                {...register("pincode", { required: "Pincode is required", pattern: /^[0-9]{6}$/ })} value={pincode || ''} // pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i 
                                                                // className={email?.length != null && email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? "form-control is-valid" : "form-control is-invalid"}
                                                                onChange={(e) => { setPincode(e.target.value) }}
                                                                type="text" id="what" placeholder="Pincode" />
                                                            <span style={{ color: '#ff0000' }}>{errors.pincode?.message}</span>
                                                            <span style={{ color: '#ff0000' }}>{errors.pincode?.type === 'pattern' && "Please enter a Valid Pincode"}</span>

                                                            {/* <span>{errors.pincode?.type === 'pattern' && "Please enter a pincode"}</span> */}
                                                        </div>
                                                        <div className="form-group">
                                                            <input name="text"
                                                                {...register("amount", { required: "Amount is required", pattern: /^[0-9]*$/ })} value={amount || ''} //, pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i 
                                                                // className={email?.length != null && email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? "form-control is-valid" : "form-control is-invalid"}
                                                                onChange={(e) => { setAmount(e.target.value) }}
                                                                type="text" id="what" placeholder="Amount" />
                                                            <span style={{ color: '#ff0000' }}>{errors.amount?.message}</span>
                                                            <span style={{ color: '#ff0000' }}>{errors.amount?.type === 'pattern' && "Please enter a Valid Amount"}</span>

                                                            {/* <span>{errors.amount?.type === 'pattern' && "Please enter an amount"}</span> */}
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <input name="text" 
                                                            {...register("email", { required: "Email is required", pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })} 
                                                            // value={email || ''}
                                                            className={"form-control is-valid"} 
                                                            // onChange={(e) => { setEmail(e.target.value) }} 
                                                            type="text" id="what" placeholder="Mobile No" required />
                                                        </div> */}

                                                        <button type='submit' className="btn btn-primary width-100">Continue</button>
                                                        {/* onClick={(e) => { e.preventDefault(); onSubmitData() }} */}
                                                    </div>
                                                    <div className="col-md-4"></div>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            )
                        case 5:
                            return (
                                <LoadingOverlay
                                    active={reviewFormLoading}
                                    spinner
                                    styles={styles()}
                                >
                                    <form className="hero-form form">
                                        <div className="container">
                                            <div className="main-search-form">
                                                <div className="row">
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group my-4">
                                                            <h1>Payment Processing</h1>
                                                        </div>
                                                        <div className=" my-4">

                                                            <label htmlFor="what" className="col-form-label p-2 my-2" style={{ fontSize: "20px", color: '#ff0000' }}>Select Payment option</label>
                                                            <Select
                                                                className="basic-single my-2"
                                                                classNamePrefix="select"
                                                                placeholder="Select Payment Option"
                                                                isSearchable={isSearchable}
                                                                name="paymentOption"
                                                                value={selectedPaymentOption}
                                                                onChange={(e) => setSelectedPaymentOption(e)}
                                                                options={[{ label: 'Online', value: 'Online' }, { label: 'Offline', value: 'Offline' },]}
                                                            />
                                                        </div>

                                                        <button onClick={(e) => { e.preventDefault(); onSubmitData1(e) }} className="btn btn-primary width-100">Proceed</button>
                                                    </div>
                                                    <div className="col-md-4">

                                                    </div>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </form>
                                </LoadingOverlay>

                            )
                        case 3:
                            return (
                                <div className="container">
                                    <div class="row flex-column-reverse flex-md-row">
                                        <div class="col-md-12 mb-4">
                                            <hr />
                                            <section className='mt-4'>
                                                {
                                                    details?.paymentMode === 'Offline' ?
                                                        <div>
                                                            <h1 className='text-center mb-4'>B&nbsp;M&nbsp;C<span className='may'>&nbsp;E&nbsp;</span>P&nbsp;A&nbsp;Y&nbsp; </h1>
                                                            <p>Thank you for using B M C <span className='may'>E</span> P A Y  service. Please Download the challan of <span className='may'>Rs. {details?.amount}.00</span> for Order ID <span className='may'>{details?.orderId} and deposit it in bank </span>. Oncce paid upload signed challan to the portal for conformation, upload option will be shown in past transactions for offline payment. Only after upload user BMC will verify your payment</p>

                                                        </div>
                                                        :
                                                        <div>
                                                            <h1 className='text-center mb-4'>B&nbsp;M&nbsp;C<span className='may'>&nbsp;E&nbsp;</span>P&nbsp;A&nbsp;Y&nbsp; </h1>
                                                            <p>Thank you for using B M C <span className='may'>E</span> P A Y  service.  Your payment of amount <span className='may'>Rs. {details?.amount}.00</span> for Order ID <span className='may'>{details?.orderId}</span> is Successful. You will get a confirmation message or we will intimate you if any action required from your side.
                                                                For further queries you can visit HDFC at Vibekanand Nagar Branch </p>

                                                        </div>
                                                }

                                            </section>

                                            <div className="">
                                                <div className='text-center'>
                                                    <img src="assets/img/upload.png" style={{ height: '140px', width: '140px' }} className='logo' alt="" srcset="" />
                                                </div>
                                                <div className='my-4'>
                                                    <PDFTemplate details={details} getOrders={getOrders} />
                                                </div>
                                                <p className='text-center' >
                                                    B M C <span className='may'>E</span> P A Y is a <span className='may-2'>' e Service portal </span>
                                                    which provides an convenient way to pay application fees for multiple departments. Using this you can pay the application fees, using various payment methods like debit car, credit card, internet banking and upi.
                                                    We use a 3d secured payment way, which is approved by pci dss, so you can make the payment without worrying about security issues. You can also see the list of payments made by using your mobile number and otp. We will duly  get updates of your payments through different communication channels like email, text message and whatsapp.
                                                </p>
                                            </div>



                                            {shareMsg ? <div className="text-center ">

                                                <div className="mt-5 pt-4 mb-4">
                                                    <div className=" mt-4 mb-2 text-center">
                                                        <WhatsappShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <WhatsappIcon size={32} round={true} />
                                                        </WhatsappShareButton>
                                                        <TwitterShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <TwitterIcon size={32} round={true} />
                                                        </TwitterShareButton>
                                                        <FacebookShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <FacebookIcon size={32} round={true} />
                                                        </FacebookShareButton>
                                                        {/* <EmailShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <EmailIcon size={32} round={true} />
                                                        </EmailShareButton> */}

                                                    </div>

                                                    <p>
                                                        <div className="separator mt-4"> OR</div>
                                                    </p>
                                                    <button type="submit" className="btn btn-referalcode width-40 text-dark">{rcode}</button>


                                                    <p>

                                                    </p>

                                                </div>
                                            </div> : null}




                                            <form onSubmit={e => { handleIntrestedIn(e) }} >
                                                <div className="form-group">
                                                    {/* <input type="checkbox" value={intrestedIn} onChange={e => setIntrestedIn(true)} className='icheckbox' name="checkbox" /> */}
                                                    {/* <span>I am interested for an early bird offer. </span> */}
                                                    {/* <div className="row">
                                                        <div className="col-md-6 col-6"><button type="submit" className="btn btn-primary width-100">I am interested for an early bird offer<span><i className='fa fa-long-arrow-right'></i></span></button></div>
                                                        <div className="col-md-6 col-6"><button type="submit" className="btn btn-primary width-100">No Thanks <span><i className='fa fa-long-arrow-right'></i></span></button></div>
                                                    </div> */}


                                                </div>
                                                {intrestedInMessage}
                                                {/* <button type="submit" className="btn btn-primary h-25 width-10">Submit</button> */}
                                            </form>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                            )
                        case 4:
                            return (
                                <div className="container mt-4 mb-4">
                                    <h4 className='text-center mt-4 mb-4 '>Thank you for providing us your valuable details! You will get the best offer very soon</h4>
                                </div>
                            )
                        case 6:
                            return (
                                <div className="container mt-4 mb-4">
                                    <button className='may' onClick={async () => { await getOfflineOrders(); setStatusCode(7) }}>Back</button>
                                    <table className='table table-responsive table-bordered table-hover view-challan' style={{ boxShadow: '0 0 50px #ccc' }}>
                                        <thead style={{ background: '#FFCCCB' }}>
                                            <tr className=''>
                                                <td className='text-center'>Order Id</td>
                                                <td className='text-center'>Status</td>
                                                <td className='text-center'>File No</td>
                                                <td className='text-center w-100'>Scheme</td>
                                                <td className='text-center w-100'>Sub Scheme</td>
                                                <td className='text-center'>Applicant Name</td>
                                                <td className='text-center'>Amount</td>
                                                <td className='text-center'>Payment Mode</td>
                                                <td className='text-center'>Payment Status</td>
                                                <td className='text-center'>Verification Status</td>
                                                <td className='text-center w-100'>Challan </td>
                                                <td className='text-center w-100'>Upload</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                orders.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr className='text-center' key={index}>
                                                                <td>{item.orderId}</td>
                                                                <td>{item.paymentStatus}</td>
                                                                <td>{item.fileNo}</td>
                                                                <td>{item.scheme}</td>
                                                                <td>{item.subScheme}</td>
                                                                <td>{item.applicantName}</td>
                                                                <td>Rs. {item.amount}</td>
                                                                <td>{item.paymentMode}</td>
                                                                <td>{item.paymentStatus}</td>
                                                                <td>{item.verificationStatus}</td>
                                                                <td>
                                                                    <a className='d-flex justify-content-center align-items-center'>
                                                                        <label style={{ color: '#FF0000', cursor: 'pointer' }} className='font-weight-bold w-auto mx-2' onClick={() => printDocument('divToPrint_' + item.orderId, item.orderId)}>Download Challan</label>
                                                                        {/* <p htmlFor="upload" className='btn btn-sm btn-primary w-auto mx-1'>Upload Challan</p> */}
                                                                    </a>
                                                                </td>
                                                                {
                                                                    (item.paymentMode === 'Offline' || item.paymentMode === 'offline') &&
                                                                    <td>
                                                                        <div className='d-flex flex-column justify-content-center align-items-center'>

                                                                            {/* <p htmlFor="upload" className='btn btn-sm btn-primary w-auto mx-1'>Upload Challan</p> */}
                                                                            {
                                                                                (item?.offlineChallanUrl && item?.offlineChallanUrl.includes('https')) ?
                                                                                    <div>
                                                                                        <a
                                                                                            className='font-weight-bold w-auto mx-2'
                                                                                            style={{ color: '#FF0000', cursor: 'pointer' }}
                                                                                            href={item?.offlineChallanUrl}
                                                                                            target='_blank'
                                                                                            rel='noreferrer'
                                                                                        >
                                                                                            View Challan
                                                                                        </a>
                                                                                        <hr />
                                                                                        <label
                                                                                            htmlFor={`upload${index}`}
                                                                                            className='font-weight-bold w-auto mx-2'
                                                                                            style={{ color: '#FF0000', cursor: 'pointer' }}
                                                                                        >
                                                                                            Replace Challan
                                                                                        </label>
                                                                                    </div>
                                                                                    :
                                                                                    <label
                                                                                        htmlFor={`upload${index}`}
                                                                                        className='font-weight-bold w-auto mx-2'
                                                                                        style={{ color: '#FF0000', cursor: 'pointer' }}
                                                                                    >
                                                                                        Upload Challan
                                                                                    </label>

                                                                            }
                                                                            <input type='file' id={`upload${index}`} className='mx-2 d-none' title='Upload Challan' onChange={(e) => handleChange(e, item)} />
                                                                            {/* <PDFTemplate  details={{}} display='none'/> */}
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>

                                                            <div id={`divToPrint_${item.orderId}`} style={{ width: '35cm', height: '40cm', display: "none" }} >
                                                                <div className='d-flex justify-content-between align-items-center p-3 challan'>
                                                                    <div className='mx-2 border px-4 pt-5 position-relative'>
                                                                        <div>
                                                                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                                        </div>

                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p>Account Number : </p>
                                                                            <p>Date :</p>
                                                                        </div>
                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p className='font-weight-bold'>{item?.paymentAccountNumber}</p>
                                                                            <p>{getCurrentDate()}</p>
                                                                        </div>
                                                                        <div className='text-center my-1'>
                                                                            <p className='font-weight-bold'>Order - {item?.orderId}</p>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            <p className='my-2'>Application Details</p>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-start'>File No</td>
                                                                                        <td className='text-end'>{item?.fileNo}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Scheme Name</td>
                                                                                        <td className='text-end'>{item?.scheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Sub Scheme Name</td>
                                                                                        <td className='text-end'>{item?.subScheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Name</td>
                                                                                        <td className='text-end'>{item?.applicantName}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Contact Number</td>
                                                                                        <td className='text-end'>{item?.phoneNumber}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className='d-flex justify-content-between align-items-center my-4'>
                                                                                <p>Applicant Other Details</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    {/* <tr>
                                                                                        <td>House/Flat No</td>
                                                                                        <td>{downloadItems?.home}</td>
                                                                                    </tr> */}
                                                                                    <tr>
                                                                                        <td>Street</td>
                                                                                        <td>{item?.others?.street}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Pincode</td>
                                                                                        <td>{item?.others?.pincode}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className='my-4'>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {item?.amount}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                                }
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold'>{item?.paymentMode}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start '>Check/DD : </p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            {
                                                                                item?.paymentMode === 'online' ?
                                                                                    <div>
                                                                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                                                                        <p className=''>Payment Details</p>
                                                                                        <table className="table border my-4">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Order ID</th>
                                                                                                    <td>{item?.orderId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.epayBankTxnId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Bank Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.bankTransaction?.tracking_id}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Date</th>
                                                                                                    <td>{getCurrentDate()}</td>
                                                                                                </tr>
                                                                                                {/* <tr>
                                                                                                    <th>Time</th>
                                                                                                    <td>{new Date().toLocaleTimeString()}</td>
                                                                                                </tr> */}
                                                                                                <tr>
                                                                                                    <th>Payment Status</th>
                                                                                                    <td>{item?.paymentStatus}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    :

                                                                                    <div className='my-3'>
                                                                                        <table className="table table-bordered my-3">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <td className='text-center'>Chq/DD No.</td>
                                                                                                    <td className='text-center'>Chq/DD Date</td>
                                                                                                    <td className='text-center'>Bank/Branch</td>
                                                                                                </tr>

                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr style={{ height: '5rem' }}>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <div className='sticky-bottom'>
                                                                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='mx-2 border px-4 pt-5 position-relative'>
                                                                        <div>
                                                                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                                        </div>

                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p>Account Number : </p>
                                                                            <p>Date :</p>
                                                                        </div>
                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p className='font-weight-bold'>{item?.paymentAccountNumber}</p>
                                                                            <p>{getCurrentDate()}</p>
                                                                        </div>
                                                                        <div className='text-center my-1'>
                                                                            <p className='font-weight-bold'>Order - {item?.orderId}</p>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            <p className='my-2'>Application Details</p>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-start'>File No</td>
                                                                                        <td className='text-end'>{item?.fileNo}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Scheme Name</td>
                                                                                        <td className='text-end'>{item?.scheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Sub Scheme Name</td>
                                                                                        <td className='text-end'>{item?.subScheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Name</td>
                                                                                        <td className='text-end'>{item?.applicantName}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Contact Number</td>
                                                                                        <td className='text-end'>{item?.phoneNumber}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className='d-flex justify-content-between align-items-center my-4'>
                                                                                <p>Applicant Other Details</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    {/* <tr>
                                                                                        <td>House/Flat No</td>
                                                                                        <td>{downloadItems?.home}</td>
                                                                                    </tr> */}
                                                                                    <tr>
                                                                                        <td>Street</td>
                                                                                        <td>{item?.others?.street}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Pincode</td>
                                                                                        <td>{item?.others?.pincode}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className='my-4'>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {item?.amount}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                                }
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold'>{item?.paymentMode}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start '>Check/DD : </p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            {
                                                                                item?.paymentMode === 'online' ?
                                                                                    <div>
                                                                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                                                                        <p className=''>Payment Details</p>
                                                                                        <table className="table border my-4">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Order ID</th>
                                                                                                    <td>{item?.orderId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.epayBankTxnId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Bank Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.bankTransaction?.tracking_id}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Date</th>
                                                                                                    <td>{getCurrentDate()}</td>
                                                                                                </tr>
                                                                                                {/* <tr>
                                                                                                    <th>Time</th>
                                                                                                    <td>{new Date().toLocaleTimeString()}</td>
                                                                                                </tr> */}
                                                                                                <tr>
                                                                                                    <th>Payment Status</th>
                                                                                                    <td>{item?.paymentStatus}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    :

                                                                                    <div className='my-3'>
                                                                                        <table className="table table-bordered my-3">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <td className='text-center'>Chq/DD No.</td>
                                                                                                    <td className='text-center'>Chq/DD Date</td>
                                                                                                    <td className='text-center'>Bank/Branch</td>
                                                                                                </tr>

                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr style={{ height: '5rem' }}>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <div className='sticky-bottom'>
                                                                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='mx-2 border px-4 pt-5 position-relative'>
                                                                        <div>
                                                                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                                        </div>

                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p>Account Number : </p>
                                                                            <p>Date :</p>
                                                                        </div>
                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p className='font-weight-bold'>{item?.paymentAccountNumber}</p>
                                                                            <p>{getCurrentDate()}</p>
                                                                        </div>
                                                                        <div className='text-center my-1'>
                                                                            <p className='font-weight-bold'>Order - {item?.orderId}</p>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            <p className='my-2'>Application Details</p>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-start'>File No</td>
                                                                                        <td className='text-end'>{item?.fileNo}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Scheme Name</td>
                                                                                        <td className='text-end'>{item?.scheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Sub Scheme Name</td>
                                                                                        <td className='text-end'>{item?.subScheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Name</td>
                                                                                        <td className='text-end'>{item?.applicantName}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Contact Number</td>
                                                                                        <td className='text-end'>{item?.phoneNumber}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className='d-flex justify-content-between align-items-center my-4'>
                                                                                <p>Applicant Other Details</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    {/* <tr>
                                                                                        <td>House/Flat No</td>
                                                                                        <td>{downloadItems?.home}</td>
                                                                                    </tr> */}
                                                                                    <tr>
                                                                                        <td>Street</td>
                                                                                        <td>{item?.others?.street}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Pincode</td>
                                                                                        <td>{item?.others?.pincode}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className='my-4'>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {item?.amount}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                                }
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold'>{item?.paymentMode}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start '>Check/DD : </p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            {
                                                                                item?.paymentMode === 'online' ?
                                                                                    <div>
                                                                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                                                                        <p className=''>Payment Details</p>
                                                                                        <table className="table border my-4">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Order ID</th>
                                                                                                    <td>{item?.orderId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.epayBankTxnId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Bank Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.bankTransaction?.tracking_id}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Date</th>
                                                                                                    <td>{getCurrentDate()}</td>
                                                                                                </tr>
                                                                                                {/* <tr>
                                                                                                    <th>Time</th>
                                                                                                    <td>{new Date().toLocaleTimeString()}</td>
                                                                                                </tr> */}
                                                                                                <tr>
                                                                                                    <th>Payment Status</th>
                                                                                                    <td>{item?.paymentStatus}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    :

                                                                                    <div className='my-3'>
                                                                                        <table className="table table-bordered my-3">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <td className='text-center'>Chq/DD No.</td>
                                                                                                    <td className='text-center'>Chq/DD Date</td>
                                                                                                    <td className='text-center'>Bank/Branch</td>
                                                                                                </tr>

                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr style={{ height: '5rem' }}>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <div className='sticky-bottom'>
                                                                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='mx-2 border px-4 pt-5 position-relative'>
                                                                        <div>
                                                                            <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                                        </div>

                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p>Account Number : </p>
                                                                            <p>Date :</p>
                                                                        </div>
                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                            <p className='font-weight-bold'>{item?.paymentAccountNumber}</p>
                                                                            <p>{getCurrentDate()}</p>
                                                                        </div>
                                                                        <div className='text-center my-1'>
                                                                            <p className='font-weight-bold'>Order - {item?.orderId}</p>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                            <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            <p className='my-2'>Application Details</p>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className='text-start'>File No</td>
                                                                                        <td className='text-end'>{item?.fileNo}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Scheme Name</td>
                                                                                        <td className='text-end'>{item?.scheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Sub Scheme Name</td>
                                                                                        <td className='text-end'>{item?.subScheme}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Name</td>
                                                                                        <td className='text-end'>{item?.applicantName}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='text-start'>Contact Number</td>
                                                                                        <td className='text-end'>{item?.phoneNumber}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className='d-flex justify-content-between align-items-center my-4'>
                                                                                <p>Applicant Other Details</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <table className="table border my-3">
                                                                                <tbody>
                                                                                    {/* <tr>
                                                                                        <td>House/Flat No</td>
                                                                                        <td>{downloadItems?.home}</td>
                                                                                    </tr> */}
                                                                                    <tr>
                                                                                        <td>Street</td>
                                                                                        <td>{item?.others?.street}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Pincode</td>
                                                                                        <td>{item?.others?.pincode}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className='my-4'>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold fs-3'>Amount : Rs. {item?.amount}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                                }
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className='text-start font-weight-bold'>{item?.paymentMode}</p>
                                                                                {
                                                                                    item?.paymentMode === 'offline' && <p className='text-start '>Check/DD : </p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='my-3'>
                                                                            {
                                                                                item?.paymentMode === 'online' ?
                                                                                    <div>
                                                                                        <p className='font-weight-bold text-center'>Online Paid</p>
                                                                                        <p className=''>Payment Details</p>
                                                                                        <table className="table border my-4">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Order ID</th>
                                                                                                    <td>{item?.orderId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.epayBankTxnId}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Bank Txn. ID</th>
                                                                                                    <td>{item?.bankTransaction?.bankTransaction?.tracking_id}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Date</th>
                                                                                                    <td>{getCurrentDate()}</td>
                                                                                                </tr>
                                                                                                {/* <tr>
                                                                                                    <th>Time</th>
                                                                                                    <td>{new Date().toLocaleTimeString()}</td>
                                                                                                </tr> */}
                                                                                                <tr>
                                                                                                    <th>Payment Status</th>
                                                                                                    <td>{item?.paymentStatus}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    :

                                                                                    <div className='my-3'>
                                                                                        <table className="table table-bordered my-3">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <td className='text-center'>Chq/DD No.</td>
                                                                                                    <td className='text-center'>Chq/DD Date</td>
                                                                                                    <td className='text-center'>Bank/Branch</td>
                                                                                                </tr>

                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr style={{ height: '5rem' }}>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                    <td className='text-center'></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <div className='sticky-bottom'>
                                                                                            <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                                                <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                    <div className='d-flex justify-content-center my-3'>
                                        <button
                                            onClick={() => setStatusCode(2)}
                                            className="btn btn-primary btm-sm w-auto my-2 mx-1"
                                        >
                                            Make A New Payment
                                            <span> <i className='fa fa-long-arrow-right'></i></span>
                                        </button>
                                    </div>
                                </div>
                            )
                        case 7:
                            return (
                                <div className="container mt-4 mb-4">


                                    {
                                        (reqChlnUploadOffline && reqChlnUploadOffline.length > 0) && <p className='text-center'>{<>**Hello <span className='may-2'>{capitalCase(reqChlnUploadOffline[0]?.applicantName)}</span>, to verify your offline payment you need to upload the bank verified challan for below Files </>}
                                        </p>
                                    }

                                    {(reqChlnUploadOffline && reqChlnUploadOffline.length > 0) &&

                                        reqChlnUploadOffline.map((order) => {
                                            return (<div className='d-flex justify-content-center align-items-center align-content-center my-3'>
                                                <div className='p-3 d-flex w-75 justify-content-center align-items-center align-content-center border border-warning' >
                                                    {/* <h5 className='may my-1'>&#9830;</h5> */}
                                                    <p className="text-justify my-2 mx-2">
                                                        <span className='may-2'> File Number : </span>
                                                        <span className='may'> {order.fileNo} </span>
                                                        with
                                                        <span className='may-2'> OrderId : </span>
                                                        <span className='may'> {order.orderId} </span>
                                                        for {capitalCase(order.subScheme)}, {capitalCase(order.scheme)},
                                                        <label htmlFor="upload" className='font-weight-bold w-auto mx-2' style={{ color: '#FF0000', cursor: 'pointer' }}> Click here </label>
                                                        {/* <span className='may' style={{ cursor : 'pointer'}}> Click here </span>  */}
                                                        to upload verified challan, or
                                                        <span className='may' style={{ cursor: 'pointer' }} onClick={() => printDocument('divToDownload' + order.orderId, order?.orderId)}> Click here </span> to download challan raw challan.
                                                    </p>
                                                    <input type='file' id='upload' className='mx-2 d-none' title='Upload Challan' onChange={(e) => handleChange(e, order)} />
                                                </div>
                                                <div id={`divToDownload_${order.orderId}`} style={{ width: '35cm', height: '40cm', display: "none" }} >
                                                    <div className='d-flex justify-content-between align-items-center p-3 challan'>
                                                        <div className='mx-2 border px-4 pt-5 position-relative'>
                                                            <div>
                                                                <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                            </div>

                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p>Account Number : </p>
                                                                <p>Date :</p>
                                                            </div>
                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p className='font-weight-bold'>{order?.paymentAccountNumber}</p>
                                                                <p>{getCurrentDate()}</p>
                                                            </div>
                                                            <div className='text-center my-1'>
                                                                <p className='font-weight-bold'>Order - {order?.orderId}</p>
                                                            </div>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                            </div>
                                                            <div className='my-3'>
                                                                <p className='my-2'>Application Details</p>
                                                                <table className="table border my-3">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='text-start'>File No</td>
                                                                            <td className='text-end'>{order?.fileNo}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Scheme Name</td>
                                                                            <td className='text-end'>{order?.scheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Sub Scheme Name</td>
                                                                            <td className='text-end'>{order?.subScheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Name</td>
                                                                            <td className='text-end'>{order?.applicantName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Contact Number</td>
                                                                            <td className='text-end'>{order?.phoneNumber}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className='d-flex justify-content-between align-items-center my-4'>
                                                                    <p>Applicant Other Details</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <table className="table border my-3">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Street</td>
                                                                            <td>{order?.others?.street}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Pincode</td>
                                                                            <td>{order?.others?.pincode}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className='my-4'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold fs-3'>Amount : Rs. {order?.amount}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                    }
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold'>{order?.paymentMode}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start '>Check/DD : </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='my-3'>

                                                                <div className='my-3'>
                                                                    <table className="table table-bordered my-3">
                                                                        <thead>
                                                                            <tr>
                                                                                <td className='text-center'>Chq/DD No.</td>
                                                                                <td className='text-center'>Chq/DD Date</td>
                                                                                <td className='text-center'>Bank/Branch</td>
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr style={{ height: '5rem' }}>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className='sticky-bottom'>
                                                                        <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mx-2 border px-4 pt-5 position-relative'>
                                                            <div>
                                                                <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                            </div>

                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p>Account Number : </p>
                                                                <p>Date :</p>
                                                            </div>
                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p className='font-weight-bold'>{order?.paymentAccountNumber}</p>
                                                                <p>{getCurrentDate()}</p>
                                                            </div>
                                                            <div className='text-center my-1'>
                                                                <p className='font-weight-bold'>Order - {order?.orderId}</p>
                                                            </div>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                            </div>
                                                            <div className='my-3'>
                                                                <p className='my-2'>Application Details</p>
                                                                <table className="table border my-3">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='text-start'>File No</td>
                                                                            <td className='text-end'>{order?.fileNo}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Scheme Name</td>
                                                                            <td className='text-end'>{order?.scheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Sub Scheme Name</td>
                                                                            <td className='text-end'>{order?.subScheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Name</td>
                                                                            <td className='text-end'>{order?.applicantName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Contact Number</td>
                                                                            <td className='text-end'>{order?.phoneNumber}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className='d-flex justify-content-between align-items-center my-4'>
                                                                    <p>Applicant Other Details</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <table className="table border my-3">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Street</td>
                                                                            <td>{order?.others?.street}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Pincode</td>
                                                                            <td>{order?.others?.pincode}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className='my-4'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold fs-3'>Amount : Rs. {order?.amount}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                    }
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold'>{order?.paymentMode}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start '>Check/DD : </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='my-3'>

                                                                <div className='my-3'>
                                                                    <table className="table table-bordered my-3">
                                                                        <thead>
                                                                            <tr>
                                                                                <td className='text-center'>Chq/DD No.</td>
                                                                                <td className='text-center'>Chq/DD Date</td>
                                                                                <td className='text-center'>Bank/Branch</td>
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr style={{ height: '5rem' }}>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className='sticky-bottom'>
                                                                        <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mx-2 border px-4 pt-5 position-relative'>
                                                            <div>
                                                                <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                            </div>

                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p>Account Number : </p>
                                                                <p>Date :</p>
                                                            </div>
                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p className='font-weight-bold'>{order?.paymentAccountNumber}</p>
                                                                <p>{getCurrentDate()}</p>
                                                            </div>
                                                            <div className='text-center my-1'>
                                                                <p className='font-weight-bold'>Order - {order?.orderId}</p>
                                                            </div>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                            </div>
                                                            <div className='my-3'>
                                                                <p className='my-2'>Application Details</p>
                                                                <table className="table border my-3">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='text-start'>File No</td>
                                                                            <td className='text-end'>{order?.fileNo}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Scheme Name</td>
                                                                            <td className='text-end'>{order?.scheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Sub Scheme Name</td>
                                                                            <td className='text-end'>{order?.subScheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Name</td>
                                                                            <td className='text-end'>{order?.applicantName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Contact Number</td>
                                                                            <td className='text-end'>{order?.phoneNumber}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className='d-flex justify-content-between align-items-center my-4'>
                                                                    <p>Applicant Other Details</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <table className="table border my-3">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Street</td>
                                                                            <td>{order?.others?.street}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Pincode</td>
                                                                            <td>{order?.others?.pincode}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className='my-4'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold fs-3'>Amount : Rs. {order?.amount}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                    }
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold'>{order?.paymentMode}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start '>Check/DD : </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='my-3'>

                                                                <div className='my-3'>
                                                                    <table className="table table-bordered my-3">
                                                                        <thead>
                                                                            <tr>
                                                                                <td className='text-center'>Chq/DD No.</td>
                                                                                <td className='text-center'>Chq/DD Date</td>
                                                                                <td className='text-center'>Bank/Branch</td>
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr style={{ height: '5rem' }}>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className='sticky-bottom'>
                                                                        <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mx-2 border px-4 pt-5 position-relative'>
                                                            <div>
                                                                <h5 className='mx-2 text-center'>Bhubaneswar Municipal Corporation</h5>
                                                            </div>

                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p>Account Number : </p>
                                                                <p>Date :</p>
                                                            </div>
                                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                <p className='font-weight-bold'>{order?.paymentAccountNumber}</p>
                                                                <p>{getCurrentDate()}</p>
                                                            </div>
                                                            <div className='text-center my-1'>
                                                                <p className='font-weight-bold'>Order - {order?.orderId}</p>
                                                            </div>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <img src={bmcLogo} alt='BMC Logo' className='h-25 w-25' />
                                                                <img src={hdfcLogo} alt='HDFC Logo' className='h-25 w-25' />
                                                            </div>
                                                            <div className='my-3'>
                                                                <p className='my-2'>Application Details</p>
                                                                <table className="table border my-3">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='text-start'>File No</td>
                                                                            <td className='text-end'>{order?.fileNo}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Scheme Name</td>
                                                                            <td className='text-end'>{order?.scheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Sub Scheme Name</td>
                                                                            <td className='text-end'>{order?.subScheme}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Name</td>
                                                                            <td className='text-end'>{order?.applicantName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start'>Contact Number</td>
                                                                            <td className='text-end'>{order?.phoneNumber}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className='d-flex justify-content-between align-items-center my-4'>
                                                                    <p>Applicant Other Details</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <table className="table border my-3">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Street</td>
                                                                            <td>{order?.others?.street}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Pincode</td>
                                                                            <td>{order?.others?.pincode}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className='my-4'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold fs-3'>Amount : Rs. {order?.amount}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start font-weight-bold fs-3'>Cash : </p>
                                                                    }
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p className='text-start font-weight-bold'>{order?.paymentMode}</p>
                                                                    {
                                                                        (order?.paymentMode === 'Offline' || order?.paymentMode === 'offline') && <p className='text-start '>Check/DD : </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='my-3'>

                                                                <div className='my-3'>
                                                                    <table className="table table-bordered my-3">
                                                                        <thead>
                                                                            <tr>
                                                                                <td className='text-center'>Chq/DD No.</td>
                                                                                <td className='text-center'>Chq/DD Date</td>
                                                                                <td className='text-center'>Bank/Branch</td>
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr style={{ height: '5rem' }}>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                                <td className='text-center'></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className='sticky-bottom'>
                                                                        <div className=' d-flex flex-column justify-content-center align-items-center mt-3' style={{ textAlign: 'center' }}>

                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Signature of Depositor</p>
                                                                            <p className='' style={{ marginTop: '3.5rem' }}>Receiving Branch Stamp &amp; Signature</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }

                                    <div className='d-flex justify-content-center align-items-center align-content-center'>



                                        <button
                                            onClick={() => setStatusCode(2)}
                                            className="btn btn-primary w-auto my-2 mx-1"
                                            disabled={errors?.termsCheck?.message}
                                        >
                                            Make A New Payment
                                            <span> <i className='fa fa-long-arrow-right'></i></span>
                                        </button>

                                    </div>
                                    <p
                                        onClick={() => getOrders()}
                                        className="text-center w-auto my-2 mx-1 py-1"
                                        style={{ color: '#FF0000', cursor: 'pointer', textDecoration: "underline" }}
                                    >
                                        Update / View Payments
                                    </p>
                                </div>
                            )
                        case 8:
                            return (
                                <div className="container mt-4 mb-4">
                                    <LoadingOverlay
                                        active={true}
                                        spinner
                                        styles={styles()}
                                    >
                                        <div className='my-5' style={{ height: '4rem', textAlign: 'center' }}></div>
                                    </LoadingOverlay>
                                    <div className='d-flex justify-content-center align-items-center align-content-center'>
                                        {
                                            paymentDetails &&
                                            <form id="nonseamless" method="post" name="redirect" action={paymentDetails.formUrl}>
                                                <input type="hidden" id="encRequest" name="encRequest" value={`${paymentDetails.encryptedOrderData}`} />
                                                <input type="hidden" name="access_code" id="access_code" value={`${paymentDetails.accessCode}`} />
                                                {/* <input type='submit' value='submit' /> */}
                                            </form>
                                        }



                                        {/* <button
                                            onClick={handleSuccess}
                                            className="btn btn-success w-auto my-2 mx-1"
                                            disabled={errors?.termsCheck?.message}
                                        >
                                            Success
                                            <span> <i className='fa fa-long-arrow-right'></i></span>
                                        </button>

                                        <button
                                            onClick={handleFailure}
                                            className="btn btn-primary w-auto my-2 mx-1"
                                        >
                                            Failure
                                            <span> <i className='fa fa-long-arrow-right'></i></span>
                                        </button> */}
                                    </div>
                                    <div className='text-center'>
                                        <h1 className=" center">
                                            <a style={{ color: "#ff0000" }} href="/">
                                                <ReactTypingEffect
                                                    text={["Processing Your Payment", "Verifying Payment", "Don't close this window"]}
                                                    speed={100} eraseSpeed={50} eraseDelay={1000} typingDelay={1000}
                                                />
                                            </a>
                                        </h1>
                                        {/* <span className='may-3'>Processing Your Payment </span> */}
                                    </div>
                                </div>
                            )
                        case 9:
                            return (
                                <div className="container">
                                    <div class="row flex-column-reverse flex-md-row">
                                        <div class="col-md-12 mb-4">
                                            <hr />
                                            <section className='mt-4'>
                                                <h1 className='text-center mb-4' style={{ color: '#FF0000' }}>Payment Failed</h1>
                                            </section>

                                            <div className="">
                                                <div className='text-center'>
                                                    <img src="assets/img/failure-icon.png" className='logo' alt="" srcset="" />
                                                </div>
                                                <div className='my-4'>
                                                    <p
                                                        onClick={() => setStatusCode(2)}
                                                        className="text-center w-auto my-2 mx-1 py-1"
                                                        style={{ color: '#FF0000', cursor: 'pointer', textDecoration: "underline" }}
                                                    >
                                                        Retry Payment
                                                    </p>
                                                </div>
                                                <p className='text-center' >
                                                    B M C <span className='may'>E</span> P A Y is a <span className='may-2'>' e Service portal </span>
                                                    which provides an convenient way to pay application fees for multiple departments. Using this you can pay the application fees, using various payment methods like debit car, credit card, internet banking and upi.
                                                    We use a 3d secured payment way, which is approved by pci dss, so you can make the payment without worrying about security issues. You can also see the list of payments made by using your mobile number and otp. We will duly  get updates of your payments through different communication channels like email, text message and whatsapp.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        default:
                            return (
                                <>
                                    <LoadingOverlay
                                        active={reviewFormLoading}
                                        spinner
                                        styles={styles()}
                                    >
                                        <br />
                                        <form className="hero-form form" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="container">
                                                <div className="main-search-form1">
                                                    <div className="form-row">
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-4 col-sm-12 ">
                                                            <div className="form-group">

                                                                <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Enter your Mobile Number</label>
                                                                <input name="phoneNumber" className="form-control"  {...register("phoneNumber", { required: "Phone Number is required", maxLength: 10, pattern: /^[4-9]\d{9}$/ })} value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} type="text" placeholder="Mobile Number" />
                                                                <span style={{ color: '#ff0000' }} className="" >{errors.phoneNumber?.message}</span>
                                                                <span style={{ color: '#ff0000' }} className="" >{errors.phoneNumber?.type === 'maxLength' && "Phone Number should be 10 digit"}</span>
                                                                <span style={{ color: '#ff0000' }} className="" >{errors.phoneNumber?.type === 'pattern' && "Please enter a Valid Phone Number"}</span>
                                                            </div>
                                                            <br />
                                                            <div className="form-group">

                                                                <input type="checkbox" className='icheckbox1' id='cb1' name='termsCheck' {...register('termsCheck', { required: 'Check here to Continue' })} />

                                                                <span className='checkproperty' style={{ fontSize: "12px" }}>Agree to the terms and conditions ? <span style={{ color: 'black' }}></span>  </span>
                                                                <p style={{ color: '#ff0000' }}>{errors?.termsCheck?.message}</p>

                                                            </div>
                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary w-100 my-2 mx-1"
                                                                    disabled={errors?.termsCheck?.message}
                                                                >
                                                                    Continue
                                                                </button>

                                                            </div>
                                                        </div>

                                                        {mobileMessage}

                                                        <div className="col-md-4"></div>
                                                    </div>

                                                    <br /><br />
                                                </div>
                                            </div>
                                        </form>
                                    </LoadingOverlay>
                                </>
                            )
                    }
                })()}
            </div>
            {
                statuscode !== 3 ? <div className="container">
                    <div class="row flex-column-reverse flex-md-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 mb-4">
                            <hr />
                            <section className='mt-4 mb-4'>
                                <h1 className='text-center mb-4'>B&nbsp;M&nbsp;C<span className='may'>&nbsp;E&nbsp;</span>P&nbsp;A&nbsp;Y&nbsp; ??</h1>
                                <p className='text-center' >
                                    B M C <span className='may'>E</span> P A Y is a <span className='may-2'>' e Service portal </span>
                                    which provides an convenient way to pay application fees for multiple departments. Using this you can pay the application fees, using various payment methods like debit car, credit card, internet banking and upi.
                                    We use a 3d secured payment way, which is approved by pci dss, so you can make the payment without worrying about security issues. You can also see the list of payments made by using your mobile number and otp. We will duly  get updates of your payments through different communication channels like email, text message and whatsapp.
                                </p>
                            </section>
                            <br />
                            <hr />
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div> : null
            }

            <NotificationContainer />

            <div className="background">
                <div className="background-image">
                    <img src="assets/img/footer-background-icons.jpg" alt="" />
                </div>
            </div>
        </div>
    )
}
