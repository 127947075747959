export const styles = () => ({
    overlay: (base) => ({
        ...base,
        background: 'rgba(255, 255, 255, 0.5)'
    }),
    spinner: (base) => ({
        ...base,

        '& svg circle': {
            stroke: 'rgba(255, 0, 0)'
        }
    })
})